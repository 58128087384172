import { ModuleWithProviders, NgModule, Optional, Provider, SkipSelf, Type } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StorageService } from './storage/storage.service';
import { UserDataService } from './user-data.service';
import { GatewayAuthModule } from './gateway-auth/gateway-auth.module';
import { environment } from '@env/environment';
import { RoleChecker, AlwaysHasRoleChecker } from '../core/services/classes/auth.interface';
import { IconsModule } from './icons/icons.module';
import { TranslocoCoreModule } from './transloco/transloco.module';
import { FuseConfigModule } from '@discoverer/fuse/services/config';
import { FUSE_MOCK_API_DEFAULT_DELAY } from '@discoverer/fuse/lib/mock-api';
import { FuseModule } from '@discoverer/fuse/fuse.module';
import { IgniteLayoutModule } from './layout/layout.module';
import { AppConfig } from './config/app.config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FuseMockApiInterceptor } from '@discoverer/fuse/lib/mock-api/mock-api.interceptor';

export function nullFunction(deps): any {
    return deps;
}

export function factory(): any {
    return nullFunction;
}

export const appConfig: AppConfig = {
    layout: 'modern',
    scheme: 'light',
    theme: 'brand'
};
@NgModule({
    declarations: [],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        GatewayAuthModule.forRoot(environment.auth.loginURL, environment.auth.productKey),
        IconsModule,
        // Fuse, FuseConfig & FuseMockAPI
        FuseConfigModule.forRoot(appConfig),
        FuseModule,
        IgniteLayoutModule,

        TranslocoCoreModule
    ],
    providers: [
        StorageService,
        UserDataService,
        { provide: RoleChecker, useClass: AlwaysHasRoleChecker },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: FuseMockApiInterceptor,
            multi: true
        }
        //{provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true},
        // {provide: ErrorHandler, useClass: CustomErrorHandler}
    ]
})
export class AppCoreModule {
    constructor(
        @Optional() @SkipSelf() parentModule?: AppCoreModule
    ) {
        if (parentModule) {
            throw new Error('AppCoreModule has already been loaded. Import this module in the AppModule only.');
        }
    }

    static forRoot(navigationItemsService: Provider, config?: { delay?: number }): ModuleWithProviders<AppCoreModule> {
        return {
            ngModule: AppCoreModule,
            providers: [
                navigationItemsService,
                {
                    provide: FUSE_MOCK_API_DEFAULT_DELAY,
                    useValue: config?.delay ?? 0
                }
            ]
        };
    }
}
