import { Injectable } from '@angular/core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable } from 'rxjs';

export interface Navigation
{
    compact: FuseNavigationItem[];
    default: FuseNavigationItem[];
    futuristic: FuseNavigationItem[];
    horizontal: FuseNavigationItem[];
}

@Injectable()
export abstract class AbstractNavigationItemsService {
     abstract getNavigationItems(): Observable<Navigation>;
}