import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AbstractNavigationItemsService, AppCoreModule } from '@discoverer/app-core';
import { NavigationItemsService } from './shared/navigation-items-service';




@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        // Core module of your application
        AppCoreModule.forRoot({
            provide: AbstractNavigationItemsService,
            useClass: NavigationItemsService
        }),

    ],
    providers: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
