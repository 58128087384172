
import { Injectable } from '@angular/core';
import { AbstractNavigationItemsService, Navigation } from '@discoverer/app-core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable, of } from 'rxjs';

export const navItems: FuseNavigationItem[] = [
    {
        id: 'log-entry',
        title: 'Log Entry',
        type: 'basic',
        icon: 'heroicons_outline:database',
        link: '/vochap/reports/log-entry/default'
    },
    {
        id: 'range-log-entry',
        title: 'Report',
        type: 'basic',
        icon: 'heroicons_outline:database',
        link: '/vochap/reports/range-log-entry/default'
    },
    {
        id: 'match-chemicals',
        title: 'Match Product',
        type: 'basic',
        icon: 'heroicons_outline:database',
        link: '/vochap/reports/match-product/default'
    },
    {
        id: 'option-list',
        title: 'Option-Lists',
        type: 'basic',
        icon: 'heroicons_outline:database',
        link: '/vochap/lists'
    }
];
@Injectable()
export class NavigationItemsService extends AbstractNavigationItemsService  {
     public override getNavigationItems(): Observable<Navigation> {
          return of(
            {   
                compact: navItems,
                futuristic: navItems,
                horizontal: navItems,
                default: navItems
            });
     }
}

