import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { User } from '@discoverer/app-core/user/user.types';
import { environment } from '@env/environment';
import { UserDataService } from '@discoverer/app-core/user-data.service';
import { Location } from '@angular/common';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserDataService,
        private location: Location
    ) {
    }

    async ngOnInit() {
        const user = await this._userService.getUser();
        this.user = {
            id: user.id.toString(),
            name: user.name,
            email: user.email
        }
        this._changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }


    signOut(): void {
        localStorage.removeItem('access-token');
        localStorage.clear();
        const url = new URL(window.location.href);
        url.searchParams.delete('token');
        url.searchParams.delete('userId');
        const currentUrl = this.location.path();
        const productCode = (currentUrl.split('/')[1]).toUpperCase();
        const redirectPath = encodeURIComponent(url.toString());
        const casoAuthTokenGenerator = encodeURIComponent(`/api/${productCode}/redirect?url=${redirectPath}`);
        window.location.href = `${environment.auth.API}/auth/login?redirect=${casoAuthTokenGenerator}`;
    }

}
