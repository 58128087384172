import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Location } from '@angular/common';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AUTH_PRODUCT_ID, AUTH_SERVER_URL, INVALID_LOGIN_TOKEN_MESSAGE } from './auth.model';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {

  constructor(
    private location: Location,
    @Inject(AUTH_SERVER_URL) private authServerUrl: string,
    @Inject(AUTH_PRODUCT_ID) private productCode: string,
  ) {
    const currentUrl = this.location.path();
    if (!currentUrl.includes('admin')) {
      const urlSegments = currentUrl.split('/');
      if (urlSegments.length > 1)
        productCode = (currentUrl.split('/')[1]).toUpperCase();
    }
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Dont intercept requests with credentials already, dont intercept non api requests
    if (req.headers.has('Authorization') || (req.url.indexOf('api') < 0 && req.url.indexOf('graphql') < 0)) {
      return next.handle(req);
    }

    let authRequest = (async () => {
      while (!!(new URLSearchParams(window.location.search).get('token'))) {
        // Fixes some early request before url param is read.
        // Wait for AuthService to pickup the token and remove it from the window.location before continuing.
        await (new Promise(resolve => setTimeout(resolve, 500)));
      }

      var token = localStorage.getItem('access-token');
      let newHeaders = req.headers;
      if (!!token) {
        // If we have a token, we append it to our new headers
        newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
      }
      // Finally we have to clone our request with our new headers
      const authReq = req.clone({ headers: newHeaders });

      return authReq;
    })();

    return from(authRequest)
      .pipe(switchMap(q => next.handle(q)))
      .pipe(
        tap(evt => {

        }),
        catchError((errorResponse: any) => {
          if (errorResponse instanceof HttpErrorResponse && (errorResponse.status === 401 || errorResponse.status === 403)) {
            this.goToLogin(window.location.pathname);
          } else if (errorResponse.status === 400) {
            if (errorResponse.error.message === INVALID_LOGIN_TOKEN_MESSAGE) {
              this.goToLogin(window.location.pathname);
            } else {
              return throwError(errorResponse);
            }
          }
          return of(errorResponse);
        })
      );
  }

  public goToLogin(redirectURL: string) {
    localStorage.setItem('redirect-url', redirectURL);

    localStorage.removeItem('access-token');
    localStorage.removeItem('user-id');
    localStorage.clear();
    const landingUrl = new URL(window.location.origin + '/auth/landing');
    const landingUrlEncoded = encodeURIComponent(landingUrl.toString());
    const casoAuthTokenGenerator = encodeURIComponent(`/api/${this.productCode}/redirect?url=${landingUrlEncoded}`);
    window.location.href = `${this.authServerUrl}auth/login?redirect=${casoAuthTokenGenerator}`;
  }

}
