import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, first } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {

  private redirectUrl: string = '';

  constructor(private _router: Router, private _authService: AuthService) {

  }

  async ngOnInit() {
    await this._authService.gatewayIdentityUserObservable.pipe(filter(x => x != null), first()).toPromise();
    // when user is ready redirect them!!
    const redirectURL = localStorage.getItem('redirect-url');
    console.log(`user logged in redirecting ... ${redirectURL}`);
    localStorage.removeItem('redirect-url');
    this._router.navigateByUrl(redirectURL ?? '');
  }

}
