import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { LandingComponent, LayoutComponent } from '@discoverer/app-core';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { SitesListComponent } from './ignite/sites-list/sites-list.component';


const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    {
        path: '',
        canActivate: [AppGuardService],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'vochap', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AppGuardService] },
            { path: 'vochap', outlet: 'dialog', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AppGuardService] },
            
        ]
    }

];

const routerConfig: ExtraOptions = {
    preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
